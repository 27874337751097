
import { defineComponent, ref, reactive, watch } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import { VxeFormProps } from "vxe-table";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
// import FileUploader from "@/cloudfun/components/FileUploader.vue";
import AddressSelectBox from "@/components/address-select-box/Main.vue"

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    // FileUploader,
    AddressSelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const isMemberBanned = ref(false);
    watch(() => grid.value.editingRow?.Id, async id => {
      if (!id) {
        isMemberBanned.value = false;
        return;
      }

      const resp = await model?.dispatch("ban/query", {
        condition: new Condition("IsReleaseNow", Operator.Equal).and("MemberId", Operator.Equal, id)
      });
      isMemberBanned.value = resp.length > 0;
    })

    const gridOptions: GridOptions = {
      title: "會員資訊",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      columns: [
        {
          field: "CreatedTime",
          title: "建立時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD')
        },
        {
          field: "Number",
          title: "會員編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Person.Name",
          title: "會員名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Person.MobilePhone",
          title: "手機",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.MemberStatus).find(e => e.Value === cellValue)?.Name : undefined
        },
        {
          field: "BanCount",
          title: "黑名單次數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Lv.Name",
          title: "會員等級",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          title: "紀錄",
          width: "220",
          slots: { default: "grid-record" }
        },
      ],
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "CreatedTime", order: 1 });
          return model.dispatch("member/query", params)
        } : undefined,
        queryAll: model ? () => model.dispatch('member/query') : undefined,
        save: model
          ? (params) => model.dispatch('member/save', params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        // { field: "Photo.Uri", title: "頭像", span: 24, slots: { default: "column-photo" } },
        { field: "Person.Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "姓名" } } },
        { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", props: { placeholder: "狀態" }, options: model ? Object.values(model.enums.MemberStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        // { field: "Account", title: "帳號", span: 12, itemRender: { name: "$input", props: { placeholder: "帳號", disabled: true } } },
        { field: "Email", title: "Email", span: 24, itemRender: { name: "$input", props: { placeholder: "Email" } } },
        { field: "ChangeEmail", title: "新Email", span: 24, itemRender: { name: "$input", props: { placeholder: "如有變更Email, 將會顯示於此", disabled: true } } },
        { field: "Password", title: "密碼", span: 12, itemRender: { name: "$input", props: { type: "password", placeholder: "密碼", autocomplete: "new-password" } } },
        { field: "Person.MobilePhone", title: "手機", span: 12, itemRender: { name: "$input", props: { placeholder: "手機" } } },
        { field: "Person.Nickname", title: "暱稱", span: 12, itemRender: { name: "$input", props: { placeholder: "名稱" } } },
        { field: "Person.Sex", title: "性別", span: 12, itemRender: { name: "$select", props: { placeholder: "性別" }, options: model ? Object.values(model.enums.Sex).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "Person.BirthDate", title: "生日", span: 12, itemRender: { name: "$input", props: { placeholder: "生日", type: "date" } } },
        { field: "LvId", title: "等級", span: 12, slots: { default: "column-lv-id" } },
        { field: "Person.Address", title: "地址", span: 24, slots: { default: "column-address" } },
        { field: "Remark", title: "備註", span: 24, itemRender: { name: "$textarea" } },
      ],
      rules: {
        "Person.Name": [{ required: true }],
        Email: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue || CloudFun.utils.validator.validateEmail(params.itemValue)) return new Error("Email格式錯誤");
          }
        }],
        "Person.MobilePhone": [{
          required: true,
          validator: (params) => {
            if (!params.itemValue || CloudFun.utils.validator.validatePhoneNumber(params.itemValue)) return new Error("手機格式錯誤");
          }
        }],
        Account: [{ required: true }],
        Password: [{
          required: false,
          validator: (params) => {
            if (params.itemValue) {
              const regex = new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)");
              if (!regex.test(params.itemValue)) return new Error("須8碼以上含大小寫英文、數字");
            } else if (!params.data.Id) return new Error("新用戶須設定密碼");
          },
        }],
        LvId: [{ required: true }],
        "Person.BirthDate": [{
          required: false,
          validator: (params) => {
            if (params.itemValue && new Date(params.itemValue).getTime() > new Date().getTime()) return new Error("生日必須小於今天");
          },
        }],
      }
    };

    const gradeSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇會員等級',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => model!.dispatch("memberGrade/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("memberGrade/query", params) // eslint-disable-line
      },
    }

    const banModal = reactive({
      current: {} as any,
      visible: false,
      formOptions: {
        titleWidth: 110,
        titleAlign: "right",
        items: [
          { field: "StartTime", title: "開始時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入時間" } } },
          { field: "EndTime", title: "結束時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入時間" } } },
          {
            field: "Description",
            title: "事由",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                placeholder: "請輸入事由"
              },
            },
          }
        ]
      },
      show() {
        banModal.visible = true;
        banModal.current.MemberId = grid.value.editingRow.Id;
        banModal.current.StartTime = new Date();
        banModal.current.EndTime = new Date();
        banModal.current.EndTime.setMonth(banModal.current.EndTime.getMonth() + 1);
      },
      submit() {
        if (!banModal.current.MemberId) return;
        model!.dispatch("ban/insert", banModal.current).then(() => {
          banModal.current = {};
          grid.value.refresh();
          banModal.visible = false;
          grid.value.isModalPopup = false;
        });
      },
      async unbanned() {
        if (!grid.value.editingRow.Id) return;
        const ban = (await model!.dispatch("ban/query", { condition: new Condition("MemberId", Operator.Equal, grid.value.editingRow.Id) }))[0];

        await model!.dispatch("ban/delete", ban.Id);
        grid.value.refresh();
        grid.value.isModalPopup = false;
      }
    })

    const orderGrid = ref<any>();
    const orderModal = reactive({
      visible: false,
      memberId: "",
      gridOptions: {
        canCreate: false,
        canUpdate: false,
        canRead: true,
        multiselect: false,
        toolbarConfig: {
          custom: false,
          refresh: true,
        },
        columns: [
          {
            field: "Time",
            title: "消費時間",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            formatter: ({ cellValue }) => $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
          },
          {
            field: "Number",
            title: "訂單編號",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "OrderSource",
            title: "訂單來源",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderSource).find(e => e.Value === cellValue)?.Name : undefined
          },
          {
            field: "Amount",
            title: "金額",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            align: "right",
            formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue)
          }
        ],
        promises: {
          query: model
            ? (params) => {
              if (params?.sortings?.length === 0) {
                params.sortings.push({ column: 'Time', order: 1 });
              }
              params.condition!.and("Member.Id", Operator.Equal, orderModal.memberId);
              return model.dispatch('order/query', params)
            }
            : undefined
        },
        modalConfig: { width: "80%", height: "80%" },
      } as GridOptions,
      show(id: string) {
        orderModal.visible = true;
        orderModal.memberId = id;
        if (orderGrid.value) orderGrid.value.refresh();
      },
      edit(row: any, callback: any) {
        orderDetailModal.orderId = row.Id;
        orderDetailModal.memberGradeAmount = row.MemberGradeAmount
        if (orderDetailGrid.value) orderDetailGrid.value.refresh();
        callback();
      }
    })

    const orderDetailGrid = ref<any>();
    const orderDetailModal = reactive({
      visible: false,
      orderId: "",
      memberGradeAmount: 0,
      gridOptions: {
        canCreate: false,
        canUpdate: false,
        canDelete: false,
        canRead: false,
        multiselect: false,
        toolbarConfig: {
          custom: false,
          refresh: true,
        },
        columns: [
          {
            field: "ProductName",
            title: "商品",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "SalePrice",
            title: "售價",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            align: "right",
            formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue)
          },
          {
            field: "Quantity",
            title: "數量",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            title: "金額",
            align: "right",
            formatter: ({ row }) => parseInt(row.Quantity) * parseInt(row.SalePrice),
          }
        ],
        promises: {
          query: model
            ? (params) => {
              params.condition!.and("OrderId", Operator.Equal, orderDetailModal.orderId);
              return new Promise(resolve => {
                model.dispatch('orderItem/query', params).then(async res => {
                  const products = await model.dispatch('product/query', { condition: new Condition("Id", Operator.In, res.data.map((e: any) => e.ProductId ? e.ProductId : 0)), filter: false });
                  res.data.forEach((e: any) => {
                    e.ProductName = products?.find((p: any) => p.Id === e.ProductId)?.Name ?? e.Name;
                    if (e.Name === "VIP折扣") {
                      console.log(res);
                      console.log(orderModal);
                      e.SalePrice = orderDetailModal.memberGradeAmount * -1;
                    }
                  });
                  resolve(res);
                })
              });
            }
            : undefined
        },
        modalConfig: { width: "80%", height: "80%" },
      } as GridOptions
    })

    const bonusGrid = ref<any>();
    const bonusModal = reactive({
      visible: false,
      accountBook: {} as any,
      gridOptions: {
        canCreate: false,
        canUpdate: false,
        canRead: false,
        canDelete: false,
        multiselect: false,
        toolbarConfig: {
          custom: false,
          refresh: true,
        },
        columns: [
          {
            field: "Time",
            title: "時間",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            formatter: ({ cellValue }) => $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
          },
          {
            field: "Name",
            title: "名目",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "Amount",
            title: "消費金額",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "BonusPoints",
            title: "紅利點數",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            formatter: ({ cellValue }) => $h.formatAmount(cellValue, 2)
          }
        ],
        promises: {
          query: model
            ? (params) => {
              if (!bonusModal.accountBook.Id) return undefined;
              if (params?.sortings?.length === 0) {
                params.sortings.push({ column: 'CreatedTime', order: 1 });
              }
              params.condition!.and("AccountBookId", Operator.Equal, bonusModal.accountBook.Id);
              return model.dispatch('memberAccountBookItem/query', params)
            }
            : undefined
        },
        modalConfig: { width: "80%", height: "80%", showFooter: true },
      } as GridOptions,
      async show(id: string) {
        bonusModal.visible = true;
        bonusModal.accountBook = (await model?.dispatch("memberAccountBook/query", { condition: new Condition("MemberId", Operator.Equal, id) }))[0] ?? { BonusPoints: 0 };
        if (bonusGrid.value) bonusGrid.value.refresh();
      }
    })

    return {
      grid,
      isMemberBanned,
      gridOptions,
      formOptions,
      gradeSelectOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      banModal,
      orderGrid,
      orderModal,
      bonusGrid,
      bonusModal,
      orderDetailGrid,
      orderDetailModal
    };
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      if (!row.Id) {
        row.Status = (this.$enums.MemberStatus as any).Enabled.Value;
      } else {
        const entity = await this.$model.dispatch("member/find", row.Id);
        Object.assign(row, entity);
      }
      callback();
    },
    onFormSubmit(row: any, submit: any) {
      row.Lv = row.Lv || {}; row.Lv.Id = row.LvId;
      // row.Email = row.Person.Email;
      row.Nickname = row.Person.Nickname;
      submit();
    }
  }
});
